<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />
    <router-link
      to="/pages/payment-plans"
      class="userName"
    >
      <p v-if="userBalance">
        $ {{ userBalance }}
      </p>
    </router-link>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/pages/addons"
    >
      <v-icon>mdi-apps-box</v-icon>
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-view-dashboard</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(link, i) in dropdownLinks"
            :key="`item-${i}`"
            :to="link.path"
          >
            <v-list-item-title v-text="link.label" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/pages/user"
    >
      <v-icon>mdi-account</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'
  import AuthService from '@/services/AuthService'
  import HttpConnector from '../../../../utils/http-connector'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      userBalance: null,
      userName: '',
      dropdownLinks: [{
        label: 'Twilio Credentials',
        path: '/pages/twilio-creds',
      }, {
        label: 'Video Meet Settings',
        path: '/pages/video-meet-settings',
      }, {
        label: 'Payment plans',
        path: '/pages/payment-plans',
      }, {
        label: 'Transactions',
        path: '/pages/transactions',
      }],
    }),

    computed: {
      ...mapState(['drawer']),
    },

    async mounted () {
      try {
        this.fetchBalance()
        const authService = new AuthService()
        const userData = await authService.getUser()
        if (userData && userData.profile && userData.profile.name && userData.profile.name.length) {
          this.userName = userData.profile.name
        }
      } catch (error) {}
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

      async fetchBalance () {
        try {
          const { data: balanceResponse } = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}user/balance?code=${this.apiCode}`)
          this.userBalance = parseFloat(balanceResponse.totalBalance).toFixed(2)
        } catch (error) {}
      },
    },
  }
</script>
<style lang="scss" scoped>
.userName {
  margin-top: 1rem;
  margin-right: 1rem;
  text-decoration: unset!important;
  font-weight: 600;
  font-size: 1rem;
}
</style>
